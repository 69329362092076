import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, catchError, map, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment.development';
import { LocalstorageService } from './localstorage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as FingerprintJS from '@fingerprintjs/fingerprintjs';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  hasInternetSubject = new BehaviorSubject<boolean>(true);
  consultor = new BehaviorSubject<any>(null);
  completeDeviceInfo: any = null;

  constructor(private httpClient: HttpClient,
              private router: Router,
              private localstorageService: LocalstorageService,
              private deviceService: DeviceDetectorService,
    ) {
      const phone = localstorageService.get('phone');
      const consultorName = localstorageService.get('consultorName');
      const qr = localstorageService.get('qr');
      const user = localstorageService.get('user');
      const agency = localstorageService.get('agency');
      const data = {
        phone: phone,
        name: consultorName,
        user: user,
        agency: agency,
        qrImage: qr,
      };
      this.consultor.next(data);
     }

  getNewSession(consultor) {
    return this.httpClient.post(`${ environment.serverUrl }/checkSessionUpdatingDate`, {consultor});
  }

  setConsultorUpdatingData(consultor: string) {

    const session = this.localstorageService.get('session');
    const deviceInfo = this.deviceService.getDeviceInfo();
    return this.httpClient.post(`${ environment.serverUrl }/setConsultorUpdatingData`, {consultor, session})
      .pipe(
        tap((response: any) => {
          this.localstorageService.set('session', response.token);
          this.localstorageService.set('qr', `${environment.serverUrl}/assets/qr/${ response.qrCode }`);
          this.localstorageService.set('phone', response.phone);
          this.localstorageService.set('consultorName', response.name);
          this.localstorageService.set('user', response.user);
          this.consultor.next({
            phone: response.phone,
            name: response.name,
            user: response.user,
            qrImage: `${environment.serverUrl}/assets/qr/${ response.qrCode }`
          });
        }),
        tap(() => {
          FingerprintJS.load().then(fp => {
            fp.get().then(result => {
              const fingerprint = result.visitorId;
              // Almacena la información en la variable global
              this.completeDeviceInfo = {
                ...deviceInfo,
                fingerprint,
              };
        
              this.setDeviceData(this.completeDeviceInfo).subscribe(response => {
              }, error => {
                console.error('Error', error);
              });
            });
          });      
        })
      )
    ;
  }

  getConsultorUpdatingData() {
    return this.httpClient.post(`${ environment.serverUrl }/getConsultorUpdatingData`, {})
    .pipe(
      catchError((error: any) => {
        if(error.status === 0) {
          this.hasInternetSubject.next(false);
        }
        if (error.status === 401 || (error.status === 403)) {
          this.localstorageService.clear();
          this.router.navigate(['/']);
          return this.getNewSession(null); 
        }
        return error;
      }),
      tap((response: any) => {
        if(!!response.name) {
          console.log('orden de cambiar localstorage');
          this.localstorageService.set('qr', `${environment.serverUrl}/assets/qr/${ response.qrCode }`);
          this.localstorageService.set('phone', response.phone);
          this.localstorageService.set('consultorName', response.name);
          this.localstorageService.set('user', response.user);
          this.localstorageService.set('agency', response.agency);
          
          this.consultor.next({
            phone: response.phone,    
            name: response.name,
            user: response.user,
            agency: response.agency,
            qrImage: `${environment.serverUrl}/assets/qr/${ response.qrCode }`
          });
        }
      }),
      map((response: any) => {
        this.hasInternetSubject.next(true);
        return response;
      })
    )
    ;
  }

  setActiveServices(activeServices: string[]) {
    return this.httpClient.post(`${ environment.serverUrl }/setActiveServices`, {activeServices});
  }

  getServices() {
    return this.httpClient.post(`${ environment.serverUrl }/getActiveServices`, {})
    .pipe(
      catchError((error: any) => {
        if(error.status === 0) {
          this.hasInternetSubject.next(false);
        }
        if (error.status === 401 || (error.status === 403)) {
          this.localstorageService.clear();
          this.router.navigate(['/']);
          return this.getNewSession(null); 
        }
        return error;
      }),
      map((response: any) => {
        this.hasInternetSubject.next(true);
        return response;
      })
    )
    ;

  }

  setNewPoints(screen: string, points: number) {
    return this.httpClient.post(`${ environment.serverUrl }/setNewPoints`, {screen, points});
  }

  setTypedPersonData(identificationType: any, identification: any, email: any, phone: any) {
    return this.httpClient.post(`${ environment.serverUrl }/setTypedPersonDataUpdating`, 
      {identificationType, identification, email, phone}
    );
  }

  getTypedPersonData() {
    return this.httpClient.post(`${ environment.serverUrl }/getTypedPersonData`, {})
    .pipe(
      catchError((error: any) => {
        if(error.status === 0) {
          this.hasInternetSubject.next(false);
        }
        if (error.status === 401 || (error.status === 403)) {
          this.localstorageService.clear();
          this.router.navigate(['/']);
          return this.getNewSession(null); 
        }
        return error;
      }),
      map((response: any) => {
        this.hasInternetSubject.next(true);
        return response;
      })
    )
    ;

  }

  checkBlockIdentification(nIdentificacion: number) {
    return this.httpClient.post(`${ environment.serverUrl }/checkIdentification`, {nIdentificacion});
  }

  getAssociateData(nTipoIdentificacion: number, nIdentificacion: number) {
    return this.httpClient.post(`${ environment.serverUrl }/jgajsdghj213jhg2321sdasdweq`, {nTipoIdentificacion, nIdentificacion, update:true});
  }

  setAddressData(addressData: any) {
    return this.httpClient.post(`${ environment.serverUrl }/setAddressData`, {addressData});
  }

  getAddressData() {
    return this.httpClient.post(`${ environment.serverUrl }/getAddressData`, {});
  }

  setValidateData(data) {
    return this.httpClient.post(`${ environment.serverUrl }/setValidateDataUpdate`, {data});
  }

  sendOTP() {
    return this.httpClient.post(`${ environment.serverUrl }/sendOTP`, { });
  }

  checkOTP(code: string, phone: string) {
    return this.httpClient.post(`${ environment.serverUrl }/checkOTPUpdating`, { code, phone });
  }

  getBalance() {
    return this.httpClient.post(`${ environment.serverUrl }/getBalanceUpdating`, {});
  }

  setBalance(data: { incomeExtraActivity: number; outcome: number; assets: number; liabilities: number; }) {
    return this.httpClient.post(`${ environment.serverUrl }/setBalanceUpdating`, data);
  }

  getForeign() {
    return this.httpClient.post(`${ environment.serverUrl }/getForeign`, {});
  }

  setForeign(value: any) {
    return this.httpClient.post(`${ environment.serverUrl }/setForeign`, value);
  }

  setForeningHasPublicJobManager(value) {
    return this.httpClient.post(`${ environment.serverUrl }/setForeningHasPublicJobManager`, value);
  }

  getPDF() {
    return this.httpClient.post(`${ environment.serverUrl }/getPDFUpdatingData`, {});
  }

  setDeviceData(deviceData: any) {
    return this.httpClient.post(`${ environment.serverUrl }/app/deviceCustomer/addDevice`, deviceData);
  }

  getUserIP(): Observable<{ data: boolean }> {
    return this.httpClient.get<{ data: boolean }>(`${environment.serverUrl}/app/blackIPToken/getUserIP`)
  }

}
