import {
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  AUTHENTICATED_USER_CODES,
  ENROLLMENT_BIOMETRIC_PHOTO,
  ENROLLMENT_DOCUMENT_VALIDATE_CODES,
  ENROLLMENT_REGISTER_DATA_CODES,
  SoyYoService,
} from '../../soy-yo/services/soy-yo.service';
import { SecurityService } from '../services/security.service';
import { LogRedebanSoyyoService } from '../services/log-redeban-soyyo.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment.development';

@Component({
  selector: 'app-basic',
  templateUrl: './basic.component.html',
  styleUrls: ['./basic.component.scss'],
})
export class BasicComponent implements OnInit, OnDestroy {
  @ViewChild('warning1') warning1: ElementRef;
  @ViewChild('warning0') warning0: ElementRef;
  @ViewChild('terms') terms: ElementRef;
  backRoute = '/app/home';
  nextRoute = '/app/balance';
  errorRoute = '/error';
  wowDisplay = false;
  isTimeout = false;

  form: FormGroup;
  errorMessage: string = '';
  isNextButtonEnabled = false;
  isLoading = false;
  timer;
  hasValidationPerson = false;
  canContinue = true;
  reload = false;
  showAlertChangeData = false
  emailValidate = false;
  emailSubscription!: Subscription;
  isVinculated = false;
  btnStatus = true;
  vinculatedRoute = `${environment.serverVinculacionUrl}/consultor/`;

  public cameraPermissionStatus: string = '';

  constructor(
    private router: Router,
    private title: Title,
    private securityService: SecurityService,
    private soyYoService: SoyYoService,
    private logRedebanSoyyoService: LogRedebanSoyyoService,
  ) {
    this.title.setTitle('Actualización Digital | Datos Básico');
    this.form = new FormGroup({
      identificationType: new FormControl('', Validators.required),
      identification: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.min(100000),
          Validators.max(10000000000),
        ])
      ),
      phone: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.min(3000000000),
          Validators.max(3999999999),
        ])
      ),
      email: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.email])
      ),
      authorization: new FormControl(
        false,
        Validators.compose([Validators.requiredTrue])
      ),
    });
    this.timer = setTimeout(() => {
      this.isTimeout = true;
    }, 10000);

    this.emailSubscription = this.form.get('email')?.valueChanges.subscribe((value) => {
      if (this.form.get('email')?.valid && this.validateEmail(value)) {
        this.emailValidate = true;
      } else {
        this.emailValidate = false;
      }
    });
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  mustBe18YearsOrOlder(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value !== undefined && control.value !== null) {
      const dateValue = new Date(control.value);
      const now = new Date();
      const minimumAge = new Date();
      minimumAge.setFullYear(now.getFullYear() - 18);

      if (dateValue > minimumAge) {
        // Si la fecha del control es menor que la fecha mínima (18 años atrás), retorna un error
        return { under18: true };
      }
    }
    return null; // si no hay problema, retorna null
  }

  async ngOnInit() {
    const route = localStorage.getItem('route');
    const currentUrl = this.router.url;
    if (route && currentUrl !== route) {
      await this.router.navigateByUrl(route);
    } else {
      await this.securityService.getTypedPersonData().subscribe({
        next: async (response: any) => {
          if (response) {
            // console.log(response);
            const step = Number(
              response?.checkpoint?.split('_')[
                response?.checkpoint?.split('_')?.length - 1
              ]
            );
            this.form.patchValue({
              identificationType: response.identificationType,
              identification: response.identification,
              phone: response.phone,
              email: response.email,
            });
            if (response.identificationType) {
              this.form.get('identification')?.enable();
            } else {
              this.form.get('identification')?.disable();
            }
  
            this.form.get('identificationType')?.valueChanges.subscribe((value) => {
              if (value) {
                this.form.get('identification')?.enable();
              } else {
                this.form.get('identification')?.disable();
              }
            });
            if (step > 3) {
              this.form.patchValue({
                authorization: true,
              });

              this.form.get('identificationType')?.disable();
              this.form.get('identification')?.disable();
              this.form.get('phone')?.disable();
              this.form.get('email')?.disable();
              this.form.get('authorization')?.disable();
              if (step === 6) {
                this.canContinue = false;
              }
              this.emailValidate = true;
              this.hasValidationPerson = true;
            }
          }
        },
        error: async (error: any) => {
          // console.log(error.status);
          // console.log(error);
        },
      });
    }
  }

  redirectToVinculate(){
    window.location.href = this.vinculatedRoute+localStorage.getItem('consultor');
  }

  ngOnDestroy() {
    if (this.emailSubscription) {
      this.emailSubscription.unsubscribe();
    }
    clearTimeout(this.timer);
    clearInterval(this.timerCamera);
  }

  //@ViewChild('videoElement') videoElement: ElementRef;
  //video: any;
  timerCamera;

  async verifyBlockIdentification() {
    const identification = this.form.value.identification;
    if (!identification) {
      return;
    }
    try{
      await this.securityService.checkBlockIdentification(identification).subscribe({
        next: async (response: any) => {
          const data = response.data; 
          if(data){
            this.verifyAssociateData();
          } else {
            localStorage.setItem('route', this.errorRoute);
            this.router.navigateByUrl(this.errorRoute);
          }
        },error: async (error: any) => {
          console.error(error);
        }
      });
    } catch (error) {
      console.error('error', error);
    }
  }

  async verifyAssociateData() {
    const identification = this.form.value.identification;
    const identificationType = this.form.value.identificationType;
    try{
      await this.securityService.getAssociateData(identificationType,identification).subscribe({
        next: async (response: any) => {
          const data = response.data;
          localStorage.setItem('nAddress', data.sDireccionResidencia);
        },error: async (error: any) => {
          this.isVinculated = true;
        }
      });
    } catch (error) {
    }
  }


  async initCamera() {
    let config = { video: true, audio: false };
    let stream: any = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia(config);
      // console.log('PERMISO OK');
      // const tracks = stream.getTracks();

      // tracks.forEach(function(track) {
      //     console.log('stop', track);
      //     track.stop();
      // });

      //this.video.srcObject = stream;
    } catch (err) {
      console.error('PERMISO FAIL');
      // Aquí debes manejar el error, puedes mostrar una alerta o un mensaje en el
      // DOM para que el usuario habilite la cámara.
      // También puedes volver a intentar iniciar la cámara después de un tiempo:
    }
  }

  async checkCameraPermission() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === 'videoinput'
      );
      // console.log(videoDevices);
      if (videoDevices.length > 0) {
        // console.log('PERMISO OK 1', new Date());
      } else {
        // console.log('PERMISO FAIL 1', new Date());
      }
    } catch (err) {
      console.error('ERROR:', err);
    }
  }

  async checkCameraPermissionxxx() {
    try {
      // console.log('checkCameraPermission');
      // let params = { video: true };
      // navigator.mediaDevices.getUserMedia(params)
      //   .then((stream)=>{
      //       stream.getTracks().forEach((track)=>{
      //         console.log(track);
      //     });
      //   })
      //   .catch((err)=>{
      //     console.log('catch', 'No hay permiso para acceder a la cámara');
      //   })
      // ;
      // let errorCallback = (error)=> {
      //   if ((error.name == 'NotAllowedError') || (error.name == 'PermissionDismissedError')) {
      //          return false;
      //   }
      // };
      // if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      //    navigator.mediaDevices.getUserMedia({video:true,audio:true})
      //       .then((success)=>{
      //         if(navigator.mediaDevices || navigator.getUserMedia){
      //           let navigator : Navigator;
      //           navigator.permissions.query({name: "camera"})
      //           .then(({state}) => {
      //             if(state == 'granted'){
      //                console.log("permission granted!");
      //             }else if(state == 'denied'){
      //               console.log("permission denied!");
      //             }
      //           })
      //         }
      //    },errorCallback);
      //  }
      // Consulta el estado del permiso para acceder a la cámara
      //   const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
      //   if (permissionStatus.state === 'granted') {
      //     this.cameraPermissionStatus = 'Permiso concedido';
      //   } else if (permissionStatus.state === 'prompt') {
      //     this.cameraPermissionStatus = 'Solicitud de permiso pendiente';
      //   } else if (permissionStatus.state === 'denied') {
      //     this.cameraPermissionStatus = 'Permiso denegado';
      //   } else {
      //     this.cameraPermissionStatus = 'Permiso desconocido';
      //   }
      // } catch (err: any) {
      //   this.cameraPermissionStatus = 'Error al verificar el permiso: ' + err.message;
      // }
    } catch (err: any) {
      console.error(err);
    }
  }

  async next() {
    localStorage.setItem('route', this.nextRoute);
    this.router.navigateByUrl(this.nextRoute);
  }

  async back() {
    localStorage.setItem('route', this.backRoute);
    this.router.navigateByUrl(this.backRoute);
  }

  showPreAlertCamera() {
    this.warning0.nativeElement.classList.remove('hidden');
    this.warning0.nativeElement.classList.add('visible');
  }

  async onSubmit(): Promise<void> {
    this.warning0.nativeElement.classList.remove('visible');
    this.warning0.nativeElement.classList.add('hidden');
    this.saveLog('Step', '01-Inicia operacion', null);
    if (this.hasValidationPerson) {
      if (!this.canContinue) {
        return;
      }
      this.next();
      return;
    }

    this.isLoading = true;
    const identification = this.form.value.identification;
    const email = this.form.value.email;
    const phone = this.form.value.phone;
    const identificationType = this.form.value.identificationType;
    let documentType = 'CC';
    switch (identificationType) {
      case '1':
        documentType = 'CC';
        break;
      case '2':
        documentType = 'CE';
        break;
      case 1:
        documentType = 'CC';
        break;
      case 2:
        documentType = 'CE';
        break;
      default:
        documentType = 'CC';
        break;
    }
    this.isNextButtonEnabled = false;
    this.saveLog('Step', '02-SaveData', null);
    await this.saveData(identificationType, identification, email, phone);
    // console.log('información almacenada');
    // this.isLoading = false;

    // if(this.timerCamera) {
    //   clearInterval(this.timerCamera);
    // } else {
    //   this.initCamera();
    //   this.timerCamera = setInterval(() => this.initCamera(), 5000);
    // }
    // return;
    this.isLoading = true;
    const soyYoModel = this.soyYoService;
    soyYoModel.errorTrhowed.subscribe({
      next: async (error: any) => {
        if (error) {
          this.saveLog('Error', '106-F Validacion de usuario', error);
          this.failMessage(
            '106-F: Ha fallado la validación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
          );
        }
      },
    });
    try {
      await soyYoModel.init();
      this.saveLog('Step', '03-soyYoModel.checkingCustomerRegister', null);
      await soyYoModel.checkingCustomerRegister(documentType, identification);
      this.saveLog('Step', '04-createTransaction', null);
      await this.createTransaction(soyYoModel, documentType, identification);
      if (!soyYoModel.isCustomerRegistred()) {
        const response = await soyYoModel.registerUser(
          email,
          phone,
          documentType,
          identification
        );
        // console.log('response', response);
        if (!response) {
          this.saveLog('Error', '106-D-Validación del usuario', !response);
          this.failMessage(
            '106-D: Ha fallado la validación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
          );
          // console.log('response exit', response);
          return;
        }
        this.saveLog('Step', '05-soyYoModel.getUserRegisterDataCode', null);
        switch (soyYoModel.getUserRegisterDataCode()) {
          case ENROLLMENT_REGISTER_DATA_CODES.SUCCESS:
            this.saveLog('Step', '06-soyYoModel.captureFaceProcess', null);
            await soyYoModel.captureFaceProcess(
              email,
              phone,
              documentType,
              identification
            );
            this.saveLog('Step', '07-soyYoModel.getCaptureBiometricFaceData', null);
            switch (soyYoModel.getCaptureBiometricFaceData()) {
              case ENROLLMENT_BIOMETRIC_PHOTO.SUCCESS:
                this.saveLog('Step', '08-soyYoModel.activatedUser', null);
                await soyYoModel.activatedUser();
                this.saveLog('Step', '09-soyYoModel.getActivatedUserData', null);
                soyYoModel.getActivatedUserData();
                if (soyYoModel.isActivatedUser()) {
                  await this.getTransaction(soyYoModel);
                } else {
                  this.saveLog('Error', '106-A-Validación del usuario', !soyYoModel.isActivatedUser());
                  this.failMessage(
                    '106-A: Ha fallado la activación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
                  );
                }
                break;
              case ENROLLMENT_BIOMETRIC_PHOTO.INSUFICIENT_VALIDATION:
                this.saveLog('Error', '10-soyYoModel.enrollmentDocumentValidate', null);
                await soyYoModel.enrollmentDocumentValidate(
                  email,
                  phone,
                  documentType,
                  identification
                );
                if (!soyYoModel.isDocumentValidated()) {
                  switch (soyYoModel.getDocumentValidatedData()?.code) {
                    case ENROLLMENT_DOCUMENT_VALIDATE_CODES.FAIL_PROCESS_CAPTURE:
                      this.saveLog('Error', '107-Falló el proceso de captura de imagen', null);
                      this.failMessage(
                        '107: Falló el proceso de captura de imagen, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
                      );
                      break;
                    case ENROLLMENT_DOCUMENT_VALIDATE_CODES.MAX_INTENT_TRIES:
                      this.saveLog('Error', '108-Superó el número de intentos', null);
                      this.failMessage(
                        '108: Ha superado el número de intentos, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
                      );
                      break;
                    default:
                      this.saveLog('Error', '109-Verifique su conexión', null);
                      this.failMessage(
                        '109: Verifique su conexión e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
                      );
                      break;
                  }
                } else {
                  this.saveLog('Step', '11-soyYoModel.activatedUser()', null);
                  await soyYoModel.activatedUser();
                  this.saveLog('Step', '12-soyYoModel.getActivatedUserData()', null);
                  soyYoModel.getActivatedUserData();
                  if (soyYoModel.isActivatedUser()) {
                    await this.getTransaction(soyYoModel);
                  } else {
                    this.saveLog('Error', '109-A-Validación del usuario', !soyYoModel.isActivatedUser());
                    this.failMessage(
                      '109-A: Ha fallado la activación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
                    );
                  }
                }
                break;
              case ENROLLMENT_BIOMETRIC_PHOTO.FAIL_PHOTO:
                this.saveLog('Error', '110-Falló la captura de la foto', null);
                this.failMessage(
                  '110: Falló la captura de la foto, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
                );
                break;
              case ENROLLMENT_BIOMETRIC_PHOTO.MAX_INTENT_TRIES:
                this.saveLog('Error', '111-Superó el número de intentos', null);
                this.failMessage(
                  '111: Superó el número de intentos, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
                );
                break;
              default:
                this.saveLog('Error', '112-Verifique su conexión', null);
                this.failMessage(
                  '112: Verifique su conexión e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
                );
                break;
            }
            break;
          case ENROLLMENT_REGISTER_DATA_CODES.INCORRECT_DATA:
            this.saveLog('Error', '101-La información ingresada no es correcta', null);
            this.failMessage(
              '101: La información ingresada no es correcta, corrija e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
            );
            break;
          case ENROLLMENT_REGISTER_DATA_CODES.USER_ALREADY_REGISTER:
            this.saveLog('Error', '102-La información es coincidente con una ingresada', null);
            this.failMessage(
              '102: La información es coincidente con una ingresada, corrija e intente de nuevo o acérquese a una oficina...'
            );
            break;
          default:
            this.saveLog('Error', '103-Verifique su conexión', null);
            this.failMessage(
              '103: Verifique su conexión e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
            );
            break;
        }
      } else {
        this.saveLog('Error', '13-authenticatedUserAndGetTransactionListData', null);
        await this.authenticatedUserAndGetTransactionListData(
          soyYoModel,
          documentType,
          identification
        );
      }
    } catch (error) {
      console.error(error);
      this.saveLog('Error', '106-E-Validación del usuario', error);
      this.failMessage(
        '106-E: Ha fallado la validación del usuario, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
      );
      // console.log('catch');
    }
  }

  private async saveData(
    identificationType: any,
    identification: any,
    email: any,
    phone: any
  ) {
    return new Promise((resolve, reject) => {
      this.securityService
        .setTypedPersonData(identificationType, identification, email, phone)
        .subscribe({
          next: async (response: any) => {
            resolve(response);
          },
          error: async (error: any) => {
            // console.log(error);
            reject(error);
          },
        });
    });
  }

  async authenticatedUserAndGetTransactionListData(
    soyYoModel: SoyYoService,
    documentType,
    identification
  ) {
    await soyYoModel.authenticateUser(documentType, identification);
    if (!soyYoModel.isUserAuthenticated()) {
      switch (soyYoModel.getAuthenticatedCode()) {
        case AUTHENTICATED_USER_CODES.USER_NOT_MATCH:
          // console.log(soyYoModel.getAuthenticatedUserData());
          if (
            soyYoModel.getAuthenticatedUserData()?.data?.description ===
            'No se puede abrir la cámara, dé permiso o reintente la captura'
          ) {
            this.failMessage(
              '116A: No se puede acceder a la cámara para iniciar su validación biométrica, por favor asigne los permisos e intente nuevamente...',
              true
            );
          } else {
            this.failMessage(
              '116: Autenticación incorrecta, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
            );
          }
          break;
        case AUTHENTICATED_USER_CODES.MAX_INTENT_TRIES:
          this.failMessage(
            '117: Superó el número de intentos para el día de hoy, acérquese a una oficina o comuníquese con su asesor...'
          );
          break;
        default:
          console.error('error', soyYoModel.getAuthenticatedCode());
          this.failMessage(
            '118: Verifique su conexión e intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
          );
          break;
      }
    } else {
      await this.getTransaction(soyYoModel);
    }
  }

  private async createTransaction(
    soyYoModel: SoyYoService,
    documentType: string,
    identification: any
  ) {
    await soyYoModel.createTransaction(documentType, identification);
    if (!soyYoModel.isTransactionCreated()) {
      this.saveLog('Error', '119-createTransaction', !soyYoModel.isTransactionCreated());
      this.failMessage(
        '119: No se pudo crear la transacción, intente de nuevo, acérquese a una oficina o comuníquese con su asesor...'
      );
    }
  }

  private async getTransaction(soyYoModel: SoyYoService) {
    await soyYoModel.checkTramitesAuthorizationGetInformation();
    if (!soyYoModel.isTramitesAuthorizationGetInformation()) {
      this.failMessage(
        '120: No es posible continuar porque rechazó la autorización, intente de nuevo y acéptela. En caso de ser necesario, acérquese a una oficina o comuníquese con su asesor...'
      );
    } else {
      await soyYoModel.getTramitesListInformation();
      const responseSoyYoData = soyYoModel.getListInformationData();
      this.securityService.setValidateData(responseSoyYoData).subscribe({
        next: (response) => {
          this.wow();
        },
      });
    }
  }

  failMessage(message, relaod = false) {
    this.reload = relaod;
    this.errorMessage = message;
    this.isLoading = false;
    this.isNextButtonEnabled = false;
  }

  wow() {
    this.securityService.setNewPoints('validation', 500).subscribe();
    this.wowDisplay = true;
    this.isLoading = false;
  }

  retry() {
    this.errorMessage = '';
  }

  skip() {
    const identification = this.form.value.identification;
    const email = this.form.value.email;
    const phone = this.form.value.phone;
    const identificationType = this.form.value.identificationType;
    this.isNextButtonEnabled = false;
    this.saveData(identificationType, identification, email, phone);
    this.wow();
  }

  setLocation($event: number | null) {
    this.form.get('cityBorn')!.setValue($event);
  }

requestCameraAccess(): void {
    // Comprobar si el navegador soporta la API de medios de dispositivos
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Solicitar acceso a la cámara
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream: MediaStream) => {
          // Si se concede el acceso, mostrar un mensaje de éxito
          
          this.onSubmit()
          // Puedes usar el stream de video para mostrar en un elemento <video>
          const videoElement = document.querySelector('video');
          if (videoElement) {
            videoElement.srcObject = stream;
            videoElement.play();
          }
        })
        .catch((error: Error) => {
          // Manejar el error si el acceso a la cámara es denegado o falla
          console.error("Error al acceder a la cámara: ", error);
          alert("Error al acceder a la cámara acceso denegado");
        });
    } else {
      // Informar al usuario si el navegador no soporta la API necesaria
      alert("La API de acceso a la cámara no está soportada en este navegador.");
    }
  }

  async testingCamera() {
    this.showAlertChangeData = false
    this.warning0.nativeElement.classList.remove('visible');
    this.warning0.nativeElement.classList.add('hidden');
    const that = this;
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          // const video: HTMLVideoElement = this.videoElement.nativeElement;
          // video.srcObject = stream;
          this.onSubmit();
        })
        .catch((error) => {
          this.warning1.nativeElement.classList.remove('hidden');
          this.warning1.nativeElement.classList.add('visible');
        });
    } else {
      console.error('getUserMedia no es soportado en este navegador.');
      this.warning1.nativeElement.classList.remove('hidden');
      this.warning1.nativeElement.classList.add('visible');
    }
  }

  

  allowCamera() {}

  hiddeAlert() {
    this.warning1.nativeElement.classList.remove('visible');
    this.warning1.nativeElement.classList.add('hidden');
  }

  showTerms() {
    this.terms.nativeElement.classList.remove('hidden');
    this.terms.nativeElement.classList.add('visible');
  }

  hiddeTerms() {
    this.terms.nativeElement.classList.remove('visible');
    this.terms.nativeElement.classList.add('hidden');
  }

  saveLog(step: string, message: string, data: any) {
    const logRedebanSoyyoModel = this.logRedebanSoyyoService;
    logRedebanSoyyoModel.setLogRedebanSoyyo(step, message, data).subscribe({
      next: (response: any) => {
        //console.log(response);
      },
      error: (error: any) => {
        console.error(error);
      }
    });
  }
}
